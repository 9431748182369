<template>
  <div>
    <!--  -->

    <div class="row">
      <div class="col-9">
        <h2>AFSA - Add</h2>
      </div>
      <div class="col-3">
        <router-link to="/afsa" class="btn btn-danger btn-sm float-end"
          >Cancel</router-link
        >
      </div>
    </div>

    <Loader :loaderShow="loaderShow" />

    <Feedback :fbWrapperShow="fbWrapperShow" :fbDiv="fbDiv" :fbMsg="fbMsg" />

    <div class="row" v-if="showForm">
      <div class="col-lg-6 col-sm-12">
        <div class="card">
          <div class="card-body">
            <h4 class="mt-3 mb-3 text-center">Add Spare to AFSA</h4>
            <hr />
            <Form @submit="onSubmit">
              <div class="mb-3">
                <label>Vendor <span class="text-danger">*</span></label>
                <Field
                  as="select"
                  name="vendor"
                  class="form-control"
                  v-model="vendor"
                  :rules="isRequired"
                >
                  <option value="">Select Vendor</option>
                  <option
                    v-for="item in vendors"
                    :value="item.vendor_id"
                    :key="item.id"
                  >
                    {{ item.vendor_name.toUpperCase() }}
                  </option>
                </Field>
                <ErrorMessage name="vendor" class="text-danger" />
              </div>
              <div class="mb-3">
                <label>Category <span class="text-danger">*</span></label>
                <Field
                  as="select"
                  name="category"
                  class="form-control"
                  v-model="category"
                  :rules="isRequired"
                >
                  <option value="">Select Category</option>
                  <option
                    v-for="item in categories"
                    :value="item.category_id"
                    :key="item.id"
                  >
                    {{ item.category_title.toUpperCase() }}
                  </option>
                </Field>
                <ErrorMessage name="category" class="text-danger" />
              </div>
              <div class="mb-3">
                <label>Brand <span class="text-danger">*</span></label>
                <Field
                  as="select"
                  name="brand"
                  class="form-control"
                  v-model="brand"
                  :rules="isRequired"
                >
                  <option value="">Select Brand</option>
                  <option
                    v-for="item in brands"
                    :value="item.brand_id"
                    :key="item.id"
                  >
                    {{ item.brand_title.toUpperCase() }}
                  </option>
                </Field>
                <ErrorMessage name="brand" class="text-danger" />
              </div>
              <div class="mb-3">
                <label>Model <span class="text-danger">*</span></label>
                <Field
                  as="select"
                  name="model"
                  class="form-control"
                  v-model="model"
                  :rules="isRequired"
                >
                  <option value="">Select Model</option>
                  <option
                    v-for="item in models"
                    :value="item.model_id"
                    :key="item.id"
                  >
                    {{ item.model_title.toUpperCase() }}
                  </option>
                </Field>
                <ErrorMessage name="model" class="text-danger" />
              </div>
              <div class="mb-3">
                <label>Spare Part <span class="text-danger">*</span></label>
                <Field
                  as="select"
                  name="sparePart"
                  class="form-control"
                  v-model="sparePart"
                  :rules="isRequired"
                >
                  <option value="">Select Spare Part</option>
                  <option
                    v-for="item in sparePartsList"
                    :value="item.spl_id"
                    :key="item.id"
                  >
                    {{ item.spl_title.toUpperCase() }}
                  </option>
                </Field>
                <ErrorMessage name="sparePart" class="text-danger" />
              </div>
              <div class="mb-3">
                <label>Spare Quality <span class="text-danger">*</span></label>
                <Field
                  as="select"
                  name="sparePartQuality"
                  class="form-control"
                  v-model="sparePartQuality"
                  :rules="isRequired"
                >
                  <option value="">Select Spare Quality</option>
                  <option
                    v-for="item in sparePartsQualities"
                    :value="item.spq_id"
                    :key="item.id"
                  >
                    {{ item.spq_title.toUpperCase() }}
                  </option>
                </Field>
                <ErrorMessage name="sparePartQuality" class="text-danger" />
              </div>
              <div class="mb-3">
                <label>Rate <span class="text-danger">*</span></label>
                <Field
                  type="number"
                  name="rate"
                  class="form-control"
                  placeholder="Rate"
                  min="1"
                  v-model="rate"
                  :rules="isRequired"
                />
                <ErrorMessage name="rate" class="text-danger" />
              </div>
              <div class="mt-5">
                <button
                  type="submit"
                  class="btn btn-primary"
                  :disabled="buttonDisabled"
                >
                  Submit
                </button>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </div>

    <!--  -->
  </div>
</template>

<script>
import { CommonServices } from "@/services/CommonServices";
import Loader from "@/components/Loader.vue";
import Feedback from "@/components/Feedback.vue";

import axios from "axios";
import { POST_SPARE_PART } from "@/services/ApiConstants";

import { Form, Field, ErrorMessage } from "vee-validate";

export default {
  components: {
    Loader,
    Feedback,
    Form,
    Field,
    ErrorMessage,
  },
  data() {
    return {
      loaderShow: true,
      fbWrapperShow: false,
      fbDiv: "",
      fbMsg: "",
      showForm: true,
      vendors: [],
      categories: [],
      brands: [],
      models: [],
      sparePartsList: [],
      sparePartsQualities: [],
      vendor: "",
      category: "",
      brand: "",
      model: "",
      sparePart: "",
      sparePartQuality: "",
      rate: "",
      buttonDisabled: false,
    };
  },
  watch: {
    category(category) {
      if (category !== "") {
        this.fetchSparePartsListByCategory(category);
        this.fetchSparePartsQualitiesByCategory(category);
        this.fetchBrandsByCategory(category);
      } else {
        this.sparePartsList = [];
        this.sparePartsQualities = [];
        this.brands = [];
      }
    },
    brand(brand) {
      if (brand !== "") {
        this.fetchModelsByCategoryBrand(brand);
      } else {
        this.models = [];
      }
    },
  },
  methods: {
    async fetchAllVendors() {
      let response = await CommonServices.getAllVendors();
      if (response.success) {
        this.vendors = response.data;
      }
    },
    async fetchCategories() {
      let response = await CommonServices.getAllCategories();
      if (response.success) {
        this.categories = response.data;
      }
    },
    async fetchSparePartsListByCategory(category) {
      let response = await CommonServices.getSparePartsListByCategory(category);
      if (response.success) {
        this.sparePartsList = response.data;
      }
    },
    async fetchSparePartsQualitiesByCategory(category) {
      let response = await CommonServices.getSparePartsQualitiesByCategory(
        category
      );
      if (response.success) {
        this.sparePartsQualities = response.data;
      }
    },
    async fetchBrandsByCategory(category) {
      let response = await CommonServices.getBrandsByCategory(category);
      if (response.success) {
        this.brands = response.data;
      }
    },
    async fetchModelsByCategoryBrand(brand) {
      let response = await CommonServices.getModelsByCategoryBrand(
        this.category,
        brand
      );
      if (response.success) {
        this.models = response.data;
      }
    },
    async onSubmit() {
      this.buttonDisabled = false;
      this.fbWrapperShow = false;
      this.fbDiv = "";
      this.fbMsg = "";
      let formData = {
        vendor: this.vendor,
        category: this.category,
        brand: this.brand,
        model: this.model,
        sparePart: this.sparePart,
        sparePartQuality: this.sparePartQuality,
        rate: this.rate,
      };
      let reqUrl = `${POST_SPARE_PART}`;
      let token = await CommonServices.getUserToken();
      let headers = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      axios
        .post(reqUrl, formData, headers)
        .then((response) => {
          let responseData = response.data;
          if (responseData.success == true) {
            this.fbMsg = responseData.message + " Please wait...!";
            this.fbDiv = "alert-success";
            this.fbWrapperShow = true;
            this.showForm = false;
            setTimeout(() => {
              this.$router.push("/afsa");
            }, 2000);
          } else {
            if (responseData.status == "ok") {
              this.fbMsg = responseData.message;
            } else {
              let swapfbmsg = "<ul>";
              for (const key in responseData.message) {
                if (Object.hasOwnProperty.call(responseData.message, key)) {
                  swapfbmsg += "<li>" + responseData.message[key] + "</li>";
                }
              }
              swapfbmsg += "</ul>";
              this.fbMsg = swapfbmsg;
            }
            this.fbDiv = "alert-danger";
            this.fbWrapperShow = true;
          }
        })
        .catch((error) => {
          console.log(error.response);
          let msg = "Server error";
          this.loaderShow = false;
          this.fbWrapperShow = true;
          this.fbDiv = "alert-danger";
          this.fbMsg = msg;
        });
    },
    isRequired(value) {
      if (value && value != null) {
        return true;
      }
      return "This field is required";
    },
  },
  async created() {
    await this.fetchAllVendors();
    await this.fetchCategories();
    this.loaderShow = false;
  },
};
</script>
