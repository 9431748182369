<template>
  <div>
    <!--  -->

    <nav
      class="navbar navbar-expand-lg fixed-top bg-body-tertiary navbar-dark"
      id="mynav"
    >
      <div class="container-fluid">
        <div class="mx-3">
          <span
            class="navbar-toggler-icon sidebar-toggler"
            @click="toggleSidebar"
          ></span>
        </div>
        <router-link to="/" class="navbar-brand">MUSAFIR</router-link>
        <button
          class="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarSupportedContent">
          <ul class="navbar-nav ms-auto mb-2 mb-lg-0">
            <li class="nav-item dropdown">
              <a
                class="nav-link dropdown-toggle"
                href="#"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                {{ emp_fullname.toUpperCase() }}
              </a>
              <ul class="dropdown-menu dropdown-menu-right">
                <li>
                  <router-link to="/my-profile" class="dropdown-item"
                    >My Profile</router-link
                  >
                </li>
                <li><hr class="dropdown-divider" /></li>
                <li>
                  <router-link to="/logout" class="dropdown-item"
                    >Logout</router-link
                  >
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </nav>

    <div class="wrapper">
      <div
        class="sidebarWrapper"
        :class="{ 'sidebar-collapsed': isSidebarCollapsed }"
      >
        <div class="sidebarmenu">
          <ul class="nav flex-column">
            <li class="nav-item">
              <router-link to="/dashboard" class="nav-link"
                >Dashboard</router-link
              >
            </li>
            <li class="nav-item">
              <router-link to="/afsa" class="nav-link">AFSA</router-link>
            </li>
            <li class="nav-item">
              <router-link to="/vendors" class="nav-link">Vendors</router-link>
            </li>
            <li class="nav-item dropdown">
              <button
                class="nav-link dropdown-toggle"
                type="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                Reports
              </button>
              <ul class="dropdown-menu">
                <li>
                  <router-link to="/reportsalldata" class="dropdown-item"
                    >All Data</router-link
                  >
                </li>
                <li>
                  <router-link to="/reportsnotinterested" class="dropdown-item"
                    >Not Interested</router-link
                  >
                </li>
                <li>
                  <router-link
                    to="/reportscompleatedorders"
                    class="dropdown-item"
                    >Completed Orders</router-link
                  >
                </li>
                <li>
                  <router-link
                    to="/reportscancelledorders"
                    class="dropdown-item"
                    >Cancelled Orders</router-link
                  >
                </li>
                <li>
                  <router-link to="/reportspartnerwallet" class="dropdown-item"
                    >Partner Wallet</router-link
                  >
                </li>
                <li>
                  <router-link to="/reportsoutofcity" class="dropdown-item"
                    >Out-of-city</router-link
                  >
                </li>
              </ul>
            </li>

            <li class="nav-item dropdown">
              <button
                class="nav-link dropdown-toggle"
                type="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                Charts
              </button>
              <ul class="dropdown-menu">
                <li>
                  <router-link to="/chartsalldata" class="dropdown-item"
                    >All Data</router-link
                  >
                </li>
                <li>
                  <router-link to="/chartsnotinterested" class="dropdown-item"
                    >Not Interested</router-link
                  >
                </li>
                <li>
                  <router-link
                    to="/chartscompleatedorders"
                    class="dropdown-item"
                    >Completed Orders</router-link
                  >
                </li>
                <li>
                  <router-link to="/chartscancelledorders" class="dropdown-item"
                    >Cancelled Orders</router-link
                  >
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
      <div class="content-wrapper">
        <div class="container-fluid">
          <router-view />
        </div>
      </div>
    </div>

    <footer class="footer">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-6">&copy; MUSAFIR by <a href="">AFPL</a></div>
          <div class="col-md-6">
            <div class="text-md-end d-none d-sm-block">
              <router-link to="/about" class="footer-link"
                >About us</router-link
              >
              <router-link to="/help" class="footer-link">Help</router-link>
            </div>
          </div>
        </div>
      </div>
    </footer>

    <!--  -->
  </div>
</template>

<script>
import { CommonServices } from "@/services/CommonServices";

export default {
  data() {
    return {
      isSidebarCollapsed: false,
      emp_fullname: "MUSAFIRNAME",
    };
  },
  methods: {
    toggleSidebar() {
      this.isSidebarCollapsed = !this.isSidebarCollapsed;
    },
  },
  async created() {
    let userDetails = await CommonServices.getUserDetails();
    if (userDetails.emp_fullname != null) {
      this.emp_fullname = userDetails.emp_fullname;
    }
  },
};
</script>

<style>
#mynav {
  background-color: #0066ff !important;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
}

.dropdown-menu.dropdown-menu-right {
  left: auto;
  right: 0;
}

.wrapper {
  margin-top: 56px;
}

/* sidebar */
.sidebarWrapper {
  height: calc(100vh - 90px);
  overflow-y: auto;
  background-color: #ffffff;
  float: left;
  width: 240px;
  transition: all 0.2s ease-out;
}

.sidebarmenu {
  padding-top: 5px;
  padding-bottom: 5px;
}

.sidebar-collapsed {
  width: 0;
  transition: all 0.3s ease-out;
}

.sidebar-toggler:hover {
  cursor: pointer;
}

.sidebarmenu .nav-item {
  border-bottom: 1px solid #e2e2e2;
}

.sidebarmenu .nav-item:hover {
  background-color: #e2e2e2;
}

.sidebarmenu .nav-link {
  color: rgba(0, 0, 0, 0.7);
}

.nav-link:hover,
.nav-link:focus {
  color: rgba(0, 0, 0, 0.9);
}

/* content wrapper */
.content-wrapper {
  height: calc(100vh - 90px);
  overflow-y: auto;
  padding: 20px 8px;
}

/* footer */
.footer {
  padding-top: 5px;
  padding-bottom: 5px;
  background-color: #ffffff;
}

.footer-link {
  margin-left: 10px;
}

@media only screen and (max-width: 600px) {
  .sidebarWrapper {
    width: 45%;
  }
  .sidebar-collapsed {
    width: 0;
  }
}
</style>
