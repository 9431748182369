import config from "@/config";

const BASE_URL = config.apiUrl;

export const BASE_CITY = config.apiCity;
// AUTH
export const POST_LOGIN_M1TOM2 = `${BASE_URL}/emp/loginm1tom2`;
export const POST_SEND_LOGIN_OTP = `${BASE_URL}/emp/send_login_otp`;
export const POST_SEND_LOGIN_CREDENTIALS = `${BASE_URL}/emp/login`;
export const POST_SEND_LOGOUT = `${BASE_URL}/emp/logout`;
// COMMON
export const GET_SPARE_PARTS_LIST_BY_CATEGORY = `${BASE_URL}/spare_parts_list_by_category`;
export const GET_SPARE_PARTS_QUALITIES_BY_CATEGORY = `${BASE_URL}/spare_parts_qualities_by_category`;
export const GET_CATEGORIES_BY_CITIES = `${BASE_URL}/categories_by_cities`;
export const GET_BRANDS_BY_CATEGORY = `${BASE_URL}/brands_by_category`;
export const GET_MODELS_BY_CATEGORY_AND_BRAND = `${BASE_URL}/models_by_category_and_brand`;
export const GET_CITIES = `${BASE_URL}/cities`;
export const GET_PINCODES_BY_CITY = `${BASE_URL}/pincodes_by_city`;
export const GET_PARTNERS = `${BASE_URL}/emp/partners`;
// AFSA
export const GET_SPARE_PART = `${BASE_URL}/emp/spare_part`;
export const POST_SPARE_PART = `${BASE_URL}/emp/spare_part`;
// VENDORS
export const POST_VENDORS = `${BASE_URL}/emp/vendors`;
export const GET_VENDORS = `${BASE_URL}/emp/vendors`;
export const GET_VENDORS_DETAILS = `${BASE_URL}/emp/vendors_details`;
// REPORTS and CHARTS
export const GET_REPORTS_ALL_SKU = `${BASE_URL}/emp/reports/all_sku`;
export const GET_REPORTS_ALL_DATA = `${BASE_URL}/emp/reports/all_data`;
export const GET_REPORTS_NOT_INTERESTED = `${BASE_URL}/emp/reports/not_interested`;
export const GET_REPORTS_COMPLETED_ORDERS = `${BASE_URL}/emp/reports/completed_orders`;
export const GET_REPORTS_CANCELLED_ORDERS = `${BASE_URL}/emp/reports/cancelled_orders`;
export const GET_REPORTS_OUT_OF_CITY = `${BASE_URL}/emp/reports/out_of_city`;
export const GET_REPORTS_PARTNERS_WALLET_TRANSACTIONS = `${BASE_URL}/emp/reports/partners_wallet_transactions`;
// ARCHIVE
// export const GET_REPORTS_COMPLETED_ORDERS_AGGREGATOR = `${BASE_URL}/emp/reports/completed_orders_aggregator`;
// export const GET_REPORTS_COMPLETED_ORDERS_IN_HOUSE = `${BASE_URL}/emp/reports/completed_orders_inhouse`;
