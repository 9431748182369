<template>
  <div>
    <!--  -->

    <div class="logout-overlay">
      <div class="logout-spnner">
        <div class="spinner-border text-primary" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
      </div>
    </div>

    <!--  -->
  </div>
</template>

<script>
import { CommonServices } from "@/services/CommonServices";
import axios from "axios";
import { POST_SEND_LOGOUT } from "@/services/ApiConstants";

export default {
  methods: {
    async logout() {
      let reqUrl = `${POST_SEND_LOGOUT}`;
      let token = await CommonServices.getUserToken();
      const headers = {
        Authorization: `Bearer ${token}`,
      };
      let formData = {
        jwt: token,
      };
      axios
        .post(reqUrl, formData, {
          headers: headers,
        })
        .then((response) => {
          let responseData = response.data;
          console.log("responseData");
          console.log(responseData);
        })
        .catch((error) => {
          console.log("error");
          console.log(error.response);
        });
    },
  },
  async created() {
    await this.logout();
    await CommonServices.removeUserDetails();
    this.$router.push("login");
  },
};
</script>

<style scoped>
.logout-overlay {
  background-color: rgba(0, 0, 0, 0.25);
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
}
.logout-spnner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%);
}
</style>
