<template>
  <div>
    <!--  -->

    <div class="row">
      <div class="col-12">
        <h2>Reports - All SKU</h2>
      </div>
    </div>

    <Loader :loaderShow="loaderShow" />

    <Feedback :fbWrapperShow="fbWrapperShow" :fbDiv="fbDiv" :fbMsg="fbMsg" />

    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <button
              type="submit"
              @click="getReport"
              class="btn btn-primary"
              :disabled="buttonDisabled"
            >
              Get All SKUs
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="row mt-3" v-if="tableRowShow">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="mb-3">
              <button
                type="button"
                @click="exportToExcel"
                class="btn btn-primary btn-sm"
              >
                Export to Excel
                <span
                  class="badge bg-light text-dark ms-1"
                  v-if="dataCountLabel !== ''"
                  >{{ dataCountLabel }}</span
                >
              </button>
            </div>
            <div class="table-responsive">
              <table
                class="table table-bordered table-hover table-sm"
                id="allSkuTable"
              >
                <thead class="table-secondary">
                  <tr>
                    <th>Tax Code</th>
                    <th>City</th>
                    <th>Category</th>
                    <th>Brand</th>
                    <th>Model</th>
                    <th>Issue ID</th>
                    <th>Issue</th>
                    <th>Display Price</th>
                    <th>Discounted Percentage</th>
                    <th>Discounted Price</th>
                    <th>Convience Fee</th>
                    <th>Final Price</th>
                    <th>Lead Charge</th>
                    <th>Commission Percentage</th>
                    <th>Convience Fee Parner</th>
                  </tr>
                </thead>
                <tbody>
                  <!-- tableRowData -->
                  <tr v-for="item in tableRowData" :key="item.id">
                    <td>{{ item.tax_code }}</td>
                    <td>{{ item.city }}</td>
                    <td>{{ item.category }}</td>
                    <td>{{ item.brand }}</td>
                    <td>{{ item.model }}</td>
                    <td>{{ item.issue_id }}</td>
                    <td>{{ item.issue }}</td>
                    <td class="text-end">{{ item.issue_display_price }}</td>
                    <td class="text-end">
                      {{ item.issue_discount_percentage }}
                    </td>
                    <td class="text-end">{{ item.issue_discounted_price }}</td>
                    <td class="text-end">{{ item.convenience_fee_user }}</td>
                    <td class="text-end">{{ item.final_price }}</td>
                    <td class="text-end">{{ item.issue_lead_charge }}</td>
                    <td class="text-end">
                      {{ item.issue_commission_percentage }}
                    </td>
                    <td class="text-end">{{ item.convenience_fee_partner }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--  -->
  </div>
</template>

<script>
import { CommonServices } from "@/services/CommonServices";
import Loader from "@/components/Loader.vue";
import Feedback from "@/components/Feedback.vue";

import * as XLSX from "xlsx";
import axios from "axios";
import { GET_REPORTS_ALL_SKU } from "@/services/ApiConstants";

export default {
  components: {
    Feedback,
    Loader,
  },
  data() {
    return {
      loaderShow: true,
      fbWrapperShow: false,
      fbDiv: "",
      fbMsg: "",
      tableRowShow: false,
      tableRowData: [],
      buttonDisabled: false,
      dataCountLabel: "",
    };
  },
  methods: {
    async getReport(e) {
      e.preventDefault();
      this.buttonDisabled = true;
      this.loaderShow = true;
      this.tableRowShow = false;
      this.fbWrapperShow = false;
      this.fbDiv = "";
      this.fbMsg = "";
      this.dataCountLabel = "";
      let reqUrl = `${GET_REPORTS_ALL_SKU}`;
      let token = await CommonServices.getUserToken();
      axios
        .get(reqUrl, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          let responseData = response.data;
          // console.log("responseData");
          // console.log(responseData);
          if (responseData.success) {
            const data = responseData.data;
            this.dataCountLabel = responseData.data_count;
            this.tableRowData = [];
            for (let x in data) {
              let tax_code = data[x].tax_code;
              let city = data[x].city;
              let category = data[x].category;
              let brand = data[x].brand;
              let model = data[x].model;
              let issue_id = data[x].issue_id;
              let issue = data[x].issue;
              let issue_display_price = data[x].issue_display_price;
              let issue_discount_percentage = data[x].issue_discount_percentage;
              let issue_discounted_price = data[x].issue_discounted_price;
              let convenience_fee_user = data[x].convenience_fee_user;
              let final_price = data[x].final_price;
              let issue_lead_charge = data[x].issue_lead_charge;
              let issue_commission_percentage =
                data[x].issue_commission_percentage;
              let convenience_fee_partner = data[x].convenience_fee_partner;

              //
              this.tableRowData = [
                ...this.tableRowData,
                {
                  tax_code: tax_code,
                  city: city,
                  category: category,
                  brand: brand,
                  model: model,
                  issue_id: issue_id,
                  issue: issue,
                  issue_display_price: issue_display_price,
                  issue_discount_percentage: issue_discount_percentage,
                  issue_discounted_price: issue_discounted_price,
                  convenience_fee_user: convenience_fee_user,
                  final_price: final_price,
                  issue_lead_charge: issue_lead_charge,
                  issue_commission_percentage: issue_commission_percentage,
                  convenience_fee_partner: convenience_fee_partner,
                },
              ];
              this.buttonDisabled = false;
              this.loaderShow = false;
              this.tableRowShow = true;
              this.fbWrapperShow = false;
              this.fbDiv = "";
              this.fbMsg = "";
            }
          } else {
            let message = "";
            if (responseData.status == "not_ok") {
              let message1 = responseData.message;
              message = "<ul>";
              for (const key in message1) {
                message += "<li>" + message1[key][0] + "</li>";
              }
              message += "</ul>";
            } else {
              message = "<div>" + responseData.message + "</div>";
            }
            this.buttonDisabled = false;
            this.loaderShow = false;
            this.tableRowShow = false;
            this.fbWrapperShow = true;
            this.fbDiv = "alert-danger";
            this.fbMsg = message;
          }
        })
        .catch((error) => {
          // let response = JSON.parse(error.request.response);
          // let msg = response.message;
          console.log(error.response);
          let msg = "Server error";
          this.buttonDisabled = false;
          this.loaderShow = false;
          this.tableRowShow = false;
          this.fbWrapperShow = true;
          this.fbDiv = "alert-danger";
          this.fbMsg = msg;
        });
    },
    exportToExcel(e) {
      e.preventDefault();
      const timestamp = Date.now();
      const table = document.querySelector("#allSkuTable");
      const worksheet = XLSX.utils.table_to_sheet(table);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
      let fileName = "AFPL_all_sku_" + timestamp + ".xlsx";
      XLSX.writeFile(workbook, fileName);
    },
  },
  async created() {
    this.loaderShow = false;
  },
};
</script>
