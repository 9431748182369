<template>
  <div>
    <!--  -->

    <div class="row">
      <div class="col-9">
        <h2>Vendors</h2>
      </div>
      <div class="col-3">
        <router-link
          to="/vendors-create"
          class="btn btn-primary btn-sm float-end"
          >Add</router-link
        >
      </div>
    </div>

    <Loader :loaderShow="loaderShow" />

    <Feedback :fbWrapperShow="fbWrapperShow" :fbDiv="fbDiv" :fbMsg="fbMsg" />

    <div class="row" v-if="vendorsListShow">
      <div
        class="col-lg-6 col-sm-12"
        v-for="item in vendorsListData"
        :key="item.id"
      >
        <div class="card mb-3">
          <h5 class="card-header bg-primary text-white">
            <router-link
              class="text-white link-underline-primary"
              :to="{
                name: 'vendors-details',
                params: { v: item.vendor_id },
              }"
              >{{ item.vendor_name }}</router-link
            >
          </h5>

          <div class="card-body">
            <div class="row">
              <div class="col-lg-4 col-sm-12">
                <img
                  class="img-fluid vendor-dp"
                  :src="item.vendor_display_picture"
                  :alt="item.vendor_name"
                />
              </div>
              <div class="col-lg-8 col-sm-12">
                <div class="card-text">
                  {{ item.vendor_address_primary_no }},
                  {{ item.vendor_address_primary_line1 }} <br />
                  {{ item.vendor_address_primary_line2 }} <br />
                  {{ item.vendor_address_primary_landmark }} <br />
                  {{ item.vendor_address_primary_city }}
                  - {{ item.vendor_address_primary_pincode }}
                </div>
                <div class="mt-2" v-if="item.vsc_data.length">
                  <span
                    class="badge bg-secondary me-2"
                    v-for="vsc in item.vsc_data"
                    :key="vsc.id"
                  >
                    {{ vsc.vsc_category_title }}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div class="card-footer">
            <div class="row">
              <div class="col-12">
                <span class="me-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="1em"
                    viewBox="0 0 512 512"
                  >
                    <path
                      d="M64 32C28.7 32 0 60.7 0 96V416c0 35.3 28.7 64 64 64H384c35.3 0 64-28.7 64-64V96c0-35.3-28.7-64-64-64H64zm90.7 96.7c9.7-2.6 19.9 2.3 23.7 11.6l20 48c3.4 8.2 1 17.6-5.8 23.2L168 231.7c16.6 35.2 45.1 63.7 80.3 80.3l20.2-24.7c5.6-6.8 15-9.2 23.2-5.8l48 20c9.3 3.9 14.2 14 11.6 23.7l-12 44C336.9 378 329 384 320 384C196.3 384 96 283.7 96 160c0-9 6-16.9 14.7-19.3l44-12z"
                    />
                  </svg>
                </span>
                <span>{{ item.vendor_mobile }}</span>

                <span class="ms-3 float-end">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="1em"
                    viewBox="0 0 384 512"
                  >
                    <path
                      d="M215.7 499.2C267 435 384 279.4 384 192C384 86 298 0 192 0S0 86 0 192c0 87.4 117 243 168.3 307.2c12.3 15.3 35.1 15.3 47.4 0zM192 128a64 64 0 1 1 0 128 64 64 0 1 1 0-128z"
                    />
                  </svg>
                </span>
              </div>
              <div class="col-12" v-if="item.vendor_email">
                <span class="me-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="1em"
                    viewBox="0 0 512 512"
                  >
                    <path
                      d="M48 64C21.5 64 0 85.5 0 112c0 15.1 7.1 29.3 19.2 38.4L236.8 313.6c11.4 8.5 27 8.5 38.4 0L492.8 150.4c12.1-9.1 19.2-23.3 19.2-38.4c0-26.5-21.5-48-48-48H48zM0 176V384c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V176L294.4 339.2c-22.8 17.1-54 17.1-76.8 0L0 176z"
                    />
                  </svg>
                </span>
                <span>{{ item.vendor_email }}</span>
              </div>
              <div class="col-12" v-if="item.vendor_website">
                <span class="me-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="1em"
                    viewBox="0 0 512 512"
                  >
                    <path
                      d="M352 256c0 22.2-1.2 43.6-3.3 64H163.3c-2.2-20.4-3.3-41.8-3.3-64s1.2-43.6 3.3-64H348.7c2.2 20.4 3.3 41.8 3.3 64zm28.8-64H503.9c5.3 20.5 8.1 41.9 8.1 64s-2.8 43.5-8.1 64H380.8c2.1-20.6 3.2-42 3.2-64s-1.1-43.4-3.2-64zm112.6-32H376.7c-10-63.9-29.8-117.4-55.3-151.6c78.3 20.7 142 77.5 171.9 151.6zm-149.1 0H167.7c6.1-36.4 15.5-68.6 27-94.7c10.5-23.6 22.2-40.7 33.5-51.5C239.4 3.2 248.7 0 256 0s16.6 3.2 27.8 13.8c11.3 10.8 23 27.9 33.5 51.5c11.6 26 20.9 58.2 27 94.7zm-209 0H18.6C48.6 85.9 112.2 29.1 190.6 8.4C165.1 42.6 145.3 96.1 135.3 160zM8.1 192H131.2c-2.1 20.6-3.2 42-3.2 64s1.1 43.4 3.2 64H8.1C2.8 299.5 0 278.1 0 256s2.8-43.5 8.1-64zM194.7 446.6c-11.6-26-20.9-58.2-27-94.6H344.3c-6.1 36.4-15.5 68.6-27 94.6c-10.5 23.6-22.2 40.7-33.5 51.5C272.6 508.8 263.3 512 256 512s-16.6-3.2-27.8-13.8c-11.3-10.8-23-27.9-33.5-51.5zM135.3 352c10 63.9 29.8 117.4 55.3 151.6C112.2 482.9 48.6 426.1 18.6 352H135.3zm358.1 0c-30 74.1-93.6 130.9-171.9 151.6c25.5-34.2 45.2-87.7 55.3-151.6H493.4z"
                    />
                  </svg>
                </span>
                <span>{{ item.vendor_website }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--  -->
  </div>
</template>

<script>
import { CommonServices } from "@/services/CommonServices";
import Loader from "@/components/Loader.vue";
import Feedback from "@/components/Feedback.vue";

import axios from "axios";
import { GET_VENDORS } from "@/services/ApiConstants";

export default {
  components: {
    Feedback,
    Loader,
  },
  data() {
    return {
      loaderShow: true,
      fbWrapperShow: false,
      fbDiv: "",
      fbMsg: "",
      vendorsListShow: false,
      vendorsListData: [],
      dataCountLabel: "",
    };
  },
  methods: {
    async getVendors() {
      this.loaderShow = true;
      this.vendorsListShow = false;
      this.fbWrapperShow = false;
      this.fbDiv = "";
      this.fbMsg = "";
      let reqUrl = `${GET_VENDORS}`;
      let token = await CommonServices.getUserToken();
      axios
        .get(reqUrl, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          let responseData = response.data;
          // console.log("responseData");
          // console.log(responseData);
          if (responseData.success) {
            const data = responseData.data;
            this.dataCountLabel = responseData.data_count;
            this.vendorsListData = [];
            for (let x in data) {
              let vendor_id = data[x].vendor_id;
              let vendor_name = data[x].vendor_name;
              let vendor_mobile = data[x].vendor_mobile;
              let vendor_display_picture = data[x].vendor_display_picture;
              let vendor_created_at = data[x].vendor_created_at;
              let vendor_date = data[x].vendor_date;
              let vendor_time = data[x].vendor_time;
              let vendor_email = data[x].vendor_email;
              let vendor_website = data[x].vendor_website;
              let vendor_address_primary = data[x].vendor_address_primary;
              let vendor_spare_categories = data[x].vendor_spare_categories;

              let vendor_address_primary_no = "";
              let vendor_address_primary_line1 = "";
              let vendor_address_primary_line2 = "";
              let vendor_address_primary_landmark = "";
              let vendor_address_primary_pincode = "";
              let vendor_address_primary_city = "";
              let vendor_address_primary_state = "";
              if (vendor_address_primary.length > 0) {
                for (let l1 in vendor_address_primary) {
                  vendor_address_primary_no =
                    vendor_address_primary[l1].no + ", ";
                  vendor_address_primary_line1 =
                    vendor_address_primary[l1].address_line_1 + ", ";
                  vendor_address_primary_line2 +=
                    vendor_address_primary[l1].address_line_2 + ", ";
                  vendor_address_primary_landmark +=
                    vendor_address_primary[l1].landmark + ", ";
                  vendor_address_primary_pincode =
                    vendor_address_primary[l1].pincode;
                  vendor_address_primary_city = vendor_address_primary[l1].city;
                  vendor_address_primary_state =
                    vendor_address_primary[l1].state;
                }
              }

              let vsc_data = [];
              if (vendor_spare_categories.length > 0) {
                for (let l2 in vendor_spare_categories) {
                  vsc_data.push({
                    vsc_category_id: vendor_spare_categories[l2].category_id,
                    vsc_category_title:
                      vendor_spare_categories[l2].category_title,
                  });
                }
              }

              //
              this.vendorsListData = [
                ...this.vendorsListData,
                {
                  vendor_id: vendor_id,
                  vendor_name: vendor_name,
                  vendor_mobile: vendor_mobile,
                  vendor_display_picture: vendor_display_picture,
                  vendor_created_at: vendor_created_at,
                  vendor_date: vendor_date,
                  vendor_time: vendor_time,
                  vendor_email: vendor_email,
                  vendor_website: vendor_website,
                  vendor_address_primary_no: vendor_address_primary_no,
                  vendor_address_primary_line1: vendor_address_primary_line1,
                  vendor_address_primary_line2: vendor_address_primary_line2,
                  vendor_address_primary_landmark:
                    vendor_address_primary_landmark,
                  vendor_address_primary_pincode:
                    vendor_address_primary_pincode,
                  vendor_address_primary_city: vendor_address_primary_city,
                  vendor_address_primary_state: vendor_address_primary_state,
                  vsc_data: vsc_data,
                },
              ];
              this.loaderShow = false;
              this.vendorsListShow = true;
              this.fbWrapperShow = false;
              this.fbDiv = "";
              this.fbMsg = "";
            }
          } else {
            let message = "";
            if (responseData.status == "not_ok") {
              let message1 = responseData.message;
              message = "<ul>";
              for (const key in message1) {
                message += "<li>" + message1[key][0] + "</li>";
              }
              message += "</ul>";
            } else {
              message = "<div>" + responseData.message + "</div>";
            }
            this.loaderShow = false;
            this.vendorsListShow = false;
            this.fbWrapperShow = true;
            this.fbDiv = "alert-danger";
            this.fbMsg = message;
          }
        })
        .catch((error) => {
          console.log(error.response);
          let msg = "Server error";
          this.loaderShow = false;
          this.vendorsListShow = false;
          this.fbWrapperShow = true;
          this.fbDiv = "alert-danger";
          this.fbMsg = msg;
        });
    },
  },
  async created() {
    await this.getVendors();
    this.loaderShow = false;
  },
};
</script>

<!-- console.log("vsc_data");
console.log(this.vendorsListData);
console.log(this.vendorsListData.vsc_data);
  let vsc_category_id = "";
  let vsc_category_title = ""; -->
<!-- <span>
        {{ item.vsc_data[0] }}
      </span> -->
<!-- <span class="badge bg-secondary me-2">Mobile</span>
      <span class="badge bg-secondary me-2">Laptop</span>
      <span class="badge bg-secondary me-2">Tablet</span>
      <span class="badge bg-secondary me-2">Smartwatch</span> -->
<!-- <span>emailaddress@domain.in</span> -->
<!-- <span>domain.in</span> -->
<!-- <div class="col-lg-6 col-sm-12 col-lg-mt-2">
  <span class="me-2">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="1em"
      viewBox="0 0 512 512"
    >
      <path
        d="M48 64C21.5 64 0 85.5 0 112c0 15.1 7.1 29.3 19.2 38.4L236.8 313.6c11.4 8.5 27 8.5 38.4 0L492.8 150.4c12.1-9.1 19.2-23.3 19.2-38.4c0-26.5-21.5-48-48-48H48zM0 176V384c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V176L294.4 339.2c-22.8 17.1-54 17.1-76.8 0L0 176z"
      />
    </svg>
  </span>
  <span>Map</span>
</div> -->
<!-- <h5 class="card-title">{{ item.vendor_mobile }}</h5> -->
<!-- <span><i class="fas fa-user"></i></span> -->

<!-- <font-awesome-icon :icon="['fas', 'bars']" />
<font-awesome-icon icon="fa-solid fa-user-secret" /> -->
<!-- <font-awesome-icon :icon="['fas', 'envelope']" /> -->
<!-- <div class="row" v-if="vendorsListShow">
  <div
    class="col-lg-6 col-sm-12"
    v-for="item in vendorsListData"
    :key="item.id"
  >
    <div class="card mb-3">
      <div class="row g-0">
        <div class="col-md-4">
          <img
            :src="item.vendor_display_picture"
            class="img-fluid rounded-start"
            alt="..."
          />
        </div>
        <div class="col-md-8">
          <div class="card-body">
            <h5 class="card-title">Card title</h5>
            <p class="card-text">
              This is a wider card with supporting text below as a natural
              lead-in to additional content. This content is a little bit
              longer.
            </p>
            <p class="card-text">
              <small class="text-body-secondary"
                >Last updated 3 mins ago</small
              >
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div> -->

<!-- 
<div class="vendors-img-wrapper">
  <img
    :src="item.vendor_display_picture"
    class="img-thumbnail"
    alt="..."
  />
</div>
<div class="vendors-details-wrapper">
  <h5 class="card-title">{{ item.vendor_mobile }}</h5>
  <p class="card-text">{{ item.vendor_name }}</p>
  <a href="#" class="btn btn-primary btn-sm">Map</a>
</div>
<style>
.vendors-img-wrapper {
  border: 1px solid red;
}
.vendors-details-wrapper {
  border: 1px solid red;
}
</style> -->

<!-- <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <h4>Coming soon...!</h4>
          </div>
        </div>
      </div>
    </div> -->

<!-- <div class="row">
      <div class="col-lg-6 col-sm-12">
        <div class="card">
          <div class="card-body">
            <div>
              <img src="" alt="" />
            </div>
            <div>
              <h4>Vendor Name</h4>
              <p>9876543210</p>
            </div>
          </div>
        </div>
      </div>
    </div> -->
<!-- <img
            :src="require('@/assets/images/logo.png')"
            class="card-img-top"
            alt="..."
          /> -->
<!-- <div class="">
            <img src="" alt="" />
          </div> -->
<!-- <img :src="vendorImagePath" class="card-img-top" alt="..." /> -->

<!-- vendor_id: vendor_id, vendor_name: vendor_name, vendor_mobile:
    vendor_mobile, vendor_display_picture: vendor_display_picture,
    vendor_created_at: vendor_created_at, vendor_date: vendor_date, vendor_time:
    vendor_time, -->

<!-- console.log("data");
console.log(data); -->

<!-- // let response = JSON.parse(error.request.response);
// let msg = response.message; -->
<!-- export default {
  data() {
    return {
      vendorsListShow: false,
      vendorImagePath: "http://localhost:8081/assets/images/logo.png",
    };
  },
};
</script> -->
