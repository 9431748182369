<template>
  <div class="py-5">
    <!--  -->

    <div class="container">
      <div class="row justify-content-center">
        <div class="col-md-8 col-lg-6 col-xl-4">
          <div class="text-center">
            <img
              src="@/assets/images/erip_logo_white.png"
              alt="erip"
              height="70"
              class="mx-auto mb-4"
            />
          </div>

          <div class="card">
            <div class="card-body">
              <div class="mt-3" v-if="fbWrapperShow">
                <div class="alert" role="alert" :class="fbDiv">
                  <div v-html="fbMsg"></div>
                </div>
              </div>
              <div class="mt-3" v-if="loadingWrapperShow">
                <div class="spinner-border text-primary" role="status">
                  <span class="visually-hidden">Loading...</span>
                </div>
                <span class="ms-3">Please wait...!</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--  -->
  </div>
</template>

<script>
import axios from "axios";
import { POST_LOGIN_M1TOM2 } from "@/services/ApiConstants";

export default {
  props: ["params"],
  data() {
    return {
      loadingWrapperShow: true,
      fbWrapperShow: false,
      fbDiv: "",
      fbMsg: "",
      loginData: this.params,
    };
  },
  methods: {
    async loginm1tom2(loginData) {
      (this.loadingWrapperShow = true), (this.fbWrapperShow = false);
      this.fbDiv = "";
      this.fbMsg = "";
      let formData = {
        login_data: loginData,
      };
      let reqUrl = `${POST_LOGIN_M1TOM2}`;
      axios
        .post(reqUrl, formData)
        .then((response) => {
          let responseData = response.data;
          let msg = "";
          if (responseData.success) {
            msg = responseData.message;
            this.fbDiv = "alert-success";
            localStorage.setItem("token", responseData.authorisation.token);
            localStorage.setItem("emp_id", responseData.emp_data.emp_id);
            localStorage.setItem(
              "emp_fullname",
              responseData.emp_data.emp_fullname
            );
            localStorage.setItem(
              "emp_mobile",
              responseData.emp_data.emp_mobile
            );
            localStorage.setItem("emp_email", responseData.emp_data.emp_email);
            window.setInterval(window.location.reload(), 3000);
          } else {
            if (responseData.status == "ok") {
              msg = responseData.message;
            } else {
              for (const key in responseData.message) {
                msg += responseData.message[key];
              }
            }
            this.fbDiv = "alert-danger";
          }
          this.loadingWrapperShow = false;
          this.fbWrapperShow = true;
          this.fbMsg = msg;
        })
        .catch((error) => {
          console.log(error.response);
          this.loadingWrapperShow = false;
          this.fbWrapperShow = true;
          this.fbDiv = "alert-danger";
          this.fbMsg = "Server error";
        });
    },
  },
  async created() {
    await this.loginm1tom2(this.loginData);
  },
};
</script>

<style scoped>
.card {
  padding: 30px 15px;
  border: 0px solid #ffffff;
}
</style>

<!-- // let response = JSON.parse(error.request.response);
// let msg = response.message; -->
<!-- // this.loadingWrapperShow = true;
// this.fbWrapperShow = false; -->
<!-- console.log("loginData");
console.log(loginData); -->
<!-- <h2>URL Parameters:</h2>
    <p>Parameter: {{ $route.params.params }}</p>
    <p>Parameter 1: {{ $route.params.param1 }}</p>
    <p>Parameter 2: {{ $route.params.param2 }}</p> -->

<!-- eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbXBfaWQiOjEsImVtcF9tb2JpbGUiOiI5NjIwNzA3ODI4IiwidG9rZW5fY3JlYXRlZF9hdCI6MTcwMDQ4NzQxNiwidG9rZW5fZXhwaXJlc19hdCI6MTcwMjIxNTQxNn0.9nXm0x-yLqz2MjrZ4lu94fixKzECi3j_DssJGflPHtk -->

<!-- ZXlKaGJHY2lPaUpJVXpJMU5pSXNJblI1Y0NJNklrcFhWQ0o5LmV5SmxiWEJmYVdRaU9qRXNJbVZ0Y0Y5dGIySnBiR1VpT2lJNU5qSXdOekEzT0RJNElpd2lkRzlyWlc1ZlkzSmxZWFJsWkY5aGRDSTZNVGN3TURRNE56UXhOaXdpZEc5clpXNWZaWGh3YVhKbGMxOWhkQ0k2TVRjd01qSXhOVFF4Tm4wLjluWG0weC15THF6Mk1qclo0bHU5NGZpeEt6RUNpM2pfRHNzSkdmbFBIdGs -->
<!-- eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbXBfaWQiOjEsImVtcF9tb2JpbGUiOiI5NjIwNzA3ODI4IiwidG9rZW5fY3JlYXRlZF9hdCI6MTcwMDQ4NzQxNiwidG9rZW5fZXhwaXJlc19hdCI6MTcwMjIxNTQxNn0.9nXm0x-yLqz2MjrZ4lu94fixKzECi3j_DssJGflPHtk -->
