// const dotenv = require("dotenv");
// dotenv.config();

let apiUrl;
let apiCity;

console.log("e");
const APP_ENV = process.env.VUE_APP_ENV;
if (APP_ENV == "production") {
  console.log("p");
  apiCity = "1";
  apiUrl = "https://www.eripbee.in/api/v1";
} else if (APP_ENV == "development") {
  console.log("d");
  apiCity = "1";
  // apiUrl = "https://bee.nutshell.repair/api/v1";
  apiUrl = "https://bee.afpl.online/api/v1";
} else {
  console.log("local");
  apiCity = "4";
  apiUrl = "http://127.0.0.1:8000/api/v1";
  // apiUrl = "https://bee.nutshell.repair/api/v1";
  // apiUrl = "https://bee.afpl.online/api/v1";
  // apiUrl = "https://www.eripbee.in/api/v1";
}

//
const config = {
  apiUrl: apiUrl,
  apiCity: apiCity,
};

module.exports = config;
