<template>
  <div>
    <!--  -->

    <div class="row">
      <div class="col-9">
        <h2>AFSA</h2>
      </div>
      <div class="col-3">
        <router-link to="/afsa-create" class="btn btn-primary btn-sm float-end"
          >Add</router-link
        >
      </div>
    </div>

    <Loader :loaderShow="loaderShow" />

    <Feedback :fbWrapperShow="fbWrapperShow" :fbDiv="fbDiv" :fbMsg="fbMsg" />

    <div class="row mt-3" v-if="tableRowShow">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="table-responsive">
              <table class="table table-bordered table-hover table-sm">
                <thead class="table-secondary">
                  <tr>
                    <th>Added at</th>
                    <th>Category</th>
                    <th>Brand</th>
                    <th>Model</th>
                    <th>Spare Part Title</th>
                    <th>Spare Part Quality</th>
                    <th>Spare Rate</th>
                    <th>Vendor Name</th>
                    <th>Vendor Mobile</th>
                  </tr>
                </thead>
                <tbody>
                  <!-- tableRowData -->
                  <tr v-for="item in tableRowData" :key="item.id">
                    <td>{{ item.created_at }}</td>
                    <td>{{ item.category }}</td>
                    <td>{{ item.brand }}</td>
                    <td>{{ item.model }}</td>
                    <td>{{ item.spare_title }}</td>
                    <td>{{ item.spare_quality }}</td>
                    <td class="text-end">{{ item.spare_rate }}</td>
                    <td>{{ item.vendor_name }}</td>
                    <td>{{ item.vendor_mobile }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--  -->
  </div>
</template>

<script>
import { CommonServices } from "@/services/CommonServices";
import Loader from "@/components/Loader.vue";
import Feedback from "@/components/Feedback.vue";

import axios from "axios";
import { GET_SPARE_PART } from "@/services/ApiConstants";

export default {
  components: {
    Feedback,
    Loader,
  },
  data() {
    return {
      loaderShow: true,
      fbWrapperShow: false,
      tableRowShow: false,
      fbDiv: "",
      fbMsg: "",
      tableRowData: [],
      dataCountLabel: "",
    };
  },
  methods: {
    async getSpareParts() {
      this.loaderShow = true;
      this.tableRowShow = false;
      this.fbWrapperShow = false;
      this.fbDiv = "";
      this.fbMsg = "";
      let reqUrl = `${GET_SPARE_PART}`;
      let token = await CommonServices.getUserToken();
      axios
        .get(reqUrl, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          let responseData = response.data;
          console.log("responseData");
          console.log(responseData);
          if (responseData.success) {
            const data = responseData.data;
            this.dataCountLabel = responseData.data_count;
            this.tableRowData = [];
            for (let x in data) {
              let spare_part_id = data[x].spare_part_id;
              let created_at_secs = data[x].created_at_secs;
              let created_at = data[x].created_at;
              let date = data[x].date;
              let time = data[x].time;
              let vendor_id = data[x].vendor_id;
              let vendor_name = data[x].vendor_name.toUpperCase();
              let vendor_mobile = data[x].vendor_mobile;
              let category_id = data[x].category_id;
              let category = data[x].category.toUpperCase();
              let brand_id = data[x].brand_id;
              let brand = data[x].brand.toUpperCase();
              let model_id = data[x].model_id;
              let model = data[x].model.toUpperCase();
              let spare_title_id = data[x].spare_title_id;
              let spare_title = data[x].spare_title;
              let spare_quality_id = data[x].spare_quality_id;
              let spare_quality = data[x].spare_quality;
              let spare_rate = data[x].spare_rate;
              //
              this.tableRowData = [
                ...this.tableRowData,
                {
                  spare_part_id: spare_part_id,
                  created_at_secs: created_at_secs,
                  created_at: created_at,
                  date: date,
                  time: time,
                  vendor_id: vendor_id,
                  vendor_name: vendor_name,
                  vendor_mobile: vendor_mobile,
                  category_id: category_id,
                  category: category,
                  brand_id: brand_id,
                  brand: brand,
                  model_id: model_id,
                  model: model,
                  spare_title_id: spare_title_id,
                  spare_title: spare_title,
                  spare_quality_id: spare_quality_id,
                  spare_quality: spare_quality,
                  spare_rate: spare_rate,
                },
              ];
              this.loaderShow = false;
              this.tableRowShow = true;
              this.fbWrapperShow = false;
              this.fbDiv = "";
              this.fbMsg = "";
            }
          } else {
            let message = "";
            if (responseData.status == "not_ok") {
              let message1 = responseData.message;
              message = "<ul>";
              for (const key in message1) {
                message += "<li>" + message1[key][0] + "</li>";
              }
              message += "</ul>";
            } else {
              message = "<div>" + responseData.message + "</div>";
            }
            this.loaderShow = false;
            this.tableRowShow = false;
            this.fbWrapperShow = true;
            this.fbDiv = "alert-danger";
            this.fbMsg = message;
          }
        })
        .catch((error) => {
          console.log(error.response);
          let msg = "Server error";
          this.loaderShow = false;
          this.tableRowShow = false;
          this.fbWrapperShow = true;
          this.fbDiv = "alert-danger";
          this.fbMsg = msg;
        });
    },
  },
  async created() {
    await this.getSpareParts();
    this.loaderShow = false;
  },
};
</script>
